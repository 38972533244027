const getDateFromIsoString = function(isoDateStr) {
    const [year, month, day] = isoDateStr.split('-')
    return new Date(year, month - 1, day)
}

export default {
    name: 'helpers',
    methods: {
        formatItem: function (item, format) {
            switch(format) {
                case 'date':
                    if (!item || item.search("-") < 0) return ''
                    return getDateFromIsoString(item).toLocaleDateString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", })
                    break
                case 'kw':
                    if (!item || item.search("-") < 0) return ''
                    const weekNumber = getDateFromIsoString(item).getWeekNumber()
                    return weekNumber.week + '/' + weekNumber.year
                    break
                case 'decimal':
                    if (!item) return ''
                    //return parseFloat(item).toString().replace(',', '').replace('.', ',')
                    return parseFloat(item).toLocaleString(['de-DE', 'en'], { style: "decimal", useGrouping: true, maximumFractionDigits: 3 })
                    break
                case 'euro':
                    if (!item) return ''
                    //return item.replace(',', '').replace('.', ',') + ' €'
                    return parseFloat(item).toLocaleString(['de-DE', 'en'], { style: "currency", currency: "EUR", useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    break
                case 'proz':
                    if (!item) return ''
                    //return item.replace(',', '').replace('.', ',') + ' %'
                    return parseFloat(item/100).toLocaleString(['de-DE', 'en'], { style: "percent", useGrouping: false, maximumFractionDigits: 2 })
                    break
                case 'lookup':
                    return item
                    break
                default:
                    return item
                    break
            }
        },
        formatView(item, format) {
            switch(format) {
                case 'decimal':
                    if (!item) return ''
                    return parseFloat(item).toLocaleString(['de-DE', 'en'], { style: "decimal", maximumFractionDigits: 3 })
                    break
                case 'euro':
                    if (!item) return ''
                    return parseFloat(item).toLocaleString(['de-DE', 'en'], { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    /*
                        style           : 'currency',
                        currency        : 'JPY',
                        currencyDisplay : 'symbol',
                        useGrouping     : true
                        localeMatcher            : 'lookup',
                        style                    : 'decimal',
                        minimumIntegerDigits     : 2,
                        minimumFractionDigits    : 2,
                        maximumFractionDigits    : 3,
                        minimumSignificantDigits : 2,
                        maximumSignificantDigits : 3
                    */
                    break
                case 'proz':
                    if (!item) return ''
                    return parseFloat(item).toLocaleString(['de-DE', 'en'], { style: "decimal", maximumFractionDigits: 2 })
                    //return parseFloat(item).toString().replace(',', '').replace('.', ',')
                    break
                default:
                    return item
                    break
            }
        },
		styleItem(item, format) {
			switch(format) {
				case 'date':
					return 'color:#2196F3; font-weight: bold;'
					break
                case 'euro':
                    if(!item) return ''
                    const itemFloat = parseFloat(item.replace('.', '').replace(',', '.'))
                    if(itemFloat < 0) return 'color:#F44336; font-weight: bold;'
                    return ''
                    break
                case 'lookup':
                    return 'color:#FF9800; font-weight: bold;'
                    break
                default:
					return ''
					break
			}
		},
    }
}