<template>
	  <v-text-field class="right-aligned"
        flat solo dense hide-details readonly background-color="blue-grey darken-3"
        :prefix="!reverse[format] ? label + ':' : suffix" 
        :suffix="reverse[format] ? label + ':' : suffix" 
        :value="formatView(value, format)"
        :reverse="reverse[format]"
    ></v-text-field>
</template>
<script>
import mixins from '../helpers'
export default {
    name: 'NumberView',
	mixins: [mixins],
    props: ['label', 'format', 'name', 'value'],
    data: () => ({
        reverse: { 'default': false, 'number': false, 'date': false, 'kw': false, 'decimal': false, 'euro': false, 'proz': false }
    }),
    computed: {
        suffix() {
            return this.format == 'euro' ? '€' : (this.format == 'proz' ? '%' : '')
        }
	},
    mounted() {
    },
    methods: {
        formatNumber(value) {
            if (!value || value == '') return null
            return parseFloat(value).toFixed(2).replace(',', '').replace('.', ',')
        },
    },
}
</script>
<style scoped>
.right-aligned >>> input {
    text-align: end
}
</style>